<template>
  <v-menu :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-on="on" v-bind="attrs">
        <v-badge overlap :content="alertas.activas.length" :value="alertas.activas.length" color="error">
          <v-icon ref="bell">mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card class="pa-3" min-width="800px">
      <v-row>

        <v-col cols="12" class="d-flex">
          <v-spacer></v-spacer>
          <v-btn @click.stop="getAlerts" small color="primary">
            <v-icon left>mdi-refresh</v-icon>
            Recargar</v-btn>
        </v-col>

        <v-col cols="6">
          <v-subheader>Alertas activas</v-subheader>
          <v-divider></v-divider>

          <v-list dense class="text-no-wrap">
            <v-list-item-group>
              <v-list-item
                :to="`/detalles_estudio?idEstudio=${alert.idEstudio}`"
                v-for="alert in alertas.activas"
                ><v-list-item-content>
                  {{ alert.idEstudio }} - {{ alert.resumen }}
                </v-list-item-content>
                <v-list-item-action-text>{{
                  parseDate(alert.fechaAlerta, false, true, true)
                }}</v-list-item-action-text></v-list-item
              >
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="6">
          <v-subheader>Alertas pendientes</v-subheader>
          <v-divider></v-divider>

          <v-list dense class="text-no-wrap">
            <v-list-item-group>
              <v-list-item
                :to="`/detalles_estudio?idEstudio=${alert.idEstudio}`"
                v-for="alert in alertas.pendientes"
              >
                <v-list-item-content>
                  {{ alert.idEstudio }} - {{ alert.resumen }}
                </v-list-item-content>
                <v-list-item-action-text>{{
                  parseDate(alert.fechaAlerta, false, true, true)
                }}</v-list-item-action-text>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
  </v-menu>
</template>

<script>
import { parseDate } from "@/utils/index.js";

export default {
  data() {
    return {
      interval: null,
      alertas: {
        activas: [],
        pendientes: [],
      },
    };
  },
  methods: {
    parseDate,
    animationAndSound() {
      this.$refs.bell.$el.classList.add("animation");

      const audioCtx = new AudioContext();
      const audio = new Audio(require("@/assets/bellring.mp3"));
      audio.crossOrigin = "anonymous";
      const source = audioCtx.createMediaElementSource(audio);
      source.connect(audioCtx.destination);
      audio.play();
    },

    async getAlerts() {
      let { data: alerts } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/cronograma/alertas.php`,
      });

      const alertas = {
        pendientes: alerts.filter((a) => a.enviado == "0"),
        activas: alerts.filter((a) => a.enviado == "1"),
      };

      const onlyInLeft = (left, right, compareFunction) =>
        left.filter(
          (leftValue) =>
            !right.some((rightValue) => compareFunction(leftValue, rightValue))
        );

      const diff = [
        ...onlyInLeft(
          alertas.activas,
          this.alertas.activas,
          (a, b) => a.idNota == b.idNota
        ),
        ...onlyInLeft(
          this.alertas.activas,
          alertas.activas,
          (a, b) => a.idNota == b.idNota
        ),
      ];

      if (diff.length) {
        this.animationAndSound();
      }

      this.alertas = alertas;
    },
  },
  mounted() {
    this.getAlerts();
    this.interval = setInterval(async () => await this.getAlerts(), 120 * 1000);

    this.$refs.bell.$el.addEventListener("transitioned", () =>
      this.$refs.bell.$el.classList.remove("animation")
    );
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped lang="scss">
.clip {
  text-align: justify;
  max-height: 120px;
  max-width: 400px;
  overflow-y: hidden;
}

.animation {
  animation: ring 4s 0.7s ease-in-out;
  transform-origin: 50% 4px;
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }
  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
</style>
